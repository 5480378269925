<template>
  <div class="h100">
    <el-card class="box-card">
      <avue-crud v-bind="bindVal"
                 v-on="onEvent"
                 :search.sync="search"
                 :page.sync="page"
                 v-model="form">
      </avue-crud>

    </el-card>
  </div>
</template>

<script>
export default window.$crudCommon({

}, {
  pageSize: 'size',
  pageNum: 'page',
  name: '/sys/org',
  res: (data) => {
    return {
      total: data.total,
      data: data.records
    }
  }
})
</script>

<style lang="scss" scoped>
</style>